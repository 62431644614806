<template>
<div class='settingsClinic formContent'><div>
	<header>Update Company Info</header>
	<div class='field'>
		<div class='label'>Company:</div>
		<TextInput 
			v-model='title' 
			maxlength='100' 
			placeholder='Company...' 
			:class='{ err: errTitle }'
			@input='errTitle = false'
		/>
	</div>
	<div class='field'>
		<div class='label'>Phone:</div>
		<TextInput 
			v-model='phone' 
			maxlength='40' 
			placeholder='Phone...' 
			:class='{ err: errPhone }'
			@input='errPhone = false'
		/>
	</div>
	<SaveButton class='saveButton' @click='save' />
	<transition name='fade'><Loading v-show='working' /></transition>
</div></div>
</template>

<script>
import TextInput from '@/components/common/TextInput'
import SaveButton from '@/components/common/buttons/Save'
import Loading from '@/components/common/Loading'

export default {
	name: 'SettingsClinic',
	components: { TextInput, SaveButton, Loading },
	data() {
		return {
			title: this.$store.state.profile.organization.title,
			phone: this.$store.state.profile.organization.phone,
			errTitle: false,
			errPhone: false, 
			renderKey: 0,
			working: false
		}
	},
	methods: {
		async save() {
			this.working = true
			const [err] = await this.$store.dispatch('profile/saveOrg', {
				title: this.title,
				phone: this.phone
			})
			this.working = false
			if (err) {
				this.title = this.$store.state.profile.organization.title
				this.phone =  this.$store.state.profile.organization.phone
			} else if (!err) {
				this.$store.dispatch('flash/showAction', 'saved')
			}
		},
		showErrs(err) {
			if ('title' in err) this.errTitle = true
			if ('phone' in err) this.errPhone = true
		}
	}
}
</script>

<style lang='scss'>
.settingsClinic {
	padding-left: $size-gutter * 4;
	background: $color-white;

	> div {
		position: relative;
		max-width: 500px;
	}

	.saveButton {
		margin-left: 100px;
		margin-top: $size-gutter * 5;
		width: 250px;
		border: 1px solid $color-neutral-shadow;
	}
}
</style>
