<template>
<div class='passwordStrengthInput'>
	<password 
		defaultClass='passwordInputEl'
		v-model='password'
		@input='onInput'
		@score='onScore'
		:badge='false'
		:placeholder='placeholder'
		:secureLength='8'
	/>
	<div class='strength' :class='{ contain }'>
		<template v-if='password.length'>
			<span v-if='score <= 1' class='weak'>{{$t('elements.passwords.weak')}}</span>
			<span v-else-if='score === 2' class='strong'>{{$t('elements.passwords.better')}}</span>
			<span v-else-if='score >= 3' class='strong'>{{$t('elements.passwords.strong')}}</span>
		</template>
	</div>
</div>
</template>

<script>
import Password from 'vue-password-strength-meter'

export default {
	name: 'PasswordInput',
	props: ['placeholder', 'contain'],
	components: { Password },
	data () {
		return {
			password: '',
			score: 0
		}
    },
	methods: {
		onInput(value) {
			this.$emit('input', value)
		},
		onScore(value) {
			this.score = value
			this.$emit('score', value)
		}
	}
}
</script>

<style lang='scss'>
.passwordStrengthInput {
	width: 100%; 
	position: relative;

	.passwordInputEl {
		width: 100%;
		height: $size-control-height;
		background: $color-white;
		padding-left: $size-gutter * 2;
		padding-right: $size-gutter * 2;
	}

	.Password {
		margin: 0;
		width: 100%;
		max-width: 100%;

		.Password__group {
			width: 100%;
		}

		.Password__strength-meter {
			width: 100%;
			height: $size-gutter;
			margin: -1px 0 0 0;
			border-radius: 0;
			background: $color-neutral-accent;
			transition: background .2s linear;
			&:before, &:after { border: none;}
		}

		.Password__strength-meter--fill[data-score='0'] {
			background: $color-alert;
		}
		.Password__strength-meter--fill[data-score='1'] {
			background: $color-accent;
		}
		.Password__strength-meter--fill[data-score='2'] {
			background: $color-success;
		}
		.Password__strength-meter--fill[data-score='3'] {
			background: $color-success;
		}
		.Password__strength-meter--fill[data-score='4'] {
			background: $color-success;
		}
	}

	&.err {
		.Password__strength-meter {
			background: $color-alert !important;
		}
		.passwordInputEl {
			background: rgba($color-alert, 0.2); 
		}
	}

	.strength {
		position: absolute;
		top: 0;
		right: -200px;
		width: 200px;
		height: $size-control-height;
		line-height: $size-control-height;
		padding-left: $size-gutter * 2;
		font-size: $size-font-standard;
		white-space: nowrap;
		pointer-events: none;

		span {
			font-weight: bold;
			&.weak { color: $color-alert; }
			&.strong { color: $color-success; }
		}

		&.contain {
			right: 0;
			width: auto;
			padding-left: 0;
			padding-right: $size-gutter * 2;
		}
	}
}

</style>

