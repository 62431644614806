<template>
<div class='passwordMatch'>
	<template v-if='b && b.length'>
		<span v-if='a === b' class='match'>{{$t('elements.passwords.match')}}</span>
		<span v-else class='noMatch'>{{$t('elements.passwords.noMatch')}}</span>
	</template>
</div>
</template>

<script>
export default {
	name: 'PasswordMatch',
	props: ['a', 'b']
}
</script>

<style lang='scss'>
.passwordMatch {
	position: absolute;
	top: 0;
	right: -200px;
	width: 200px;
	height: $size-control-height;
	line-height: $size-control-height;
	padding-left: $size-gutter * 2;
	font-size: $size-font-standard;

	span {
		font-weight: bold;
		&.noMatch { color: $color-alert; }
		&.match { color: $color-success; }
	}
}
</style>

