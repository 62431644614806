<template>
<div class='settingsPassword formContent'><div>
	<header>{{$t('views.settings.password.title')}}</header>
	<p>{{$t('elements.passwords.requirements')}}</p>
	<div class='field'>
		<PasswordInput 
			v-model='oldPassword' 
			ref='oldPassword'
			:placeholder='$t("views.settings.password.phOldPassword")' 
			:class='{ err: errOldPassword }'
			@input='errOldPassword = false'
		/>
	</div>
	<div class='field'>
		<PasswordStrengthInput 
			v-model='newPassword1' 
			ref='newPassword1'
			:placeholder='$t("views.settings.password.phNewPassword1")' 
			:class='{ err: errNewPassword1 }'
			:key='renderKey'
			@input='errNewPassword1 = false'
			@score='value => score = value'
		/>
	</div>
	<div class='field newPassword2'>
		<PasswordInput 
			v-model='newPassword2' 
			ref='newPassword2'
			:disabled='score < 2'
			:placeholder='errNewPassword2 ? $t("elements.passwords.noMatch") : $t("views.settings.password.phNewPassword2")'
			:class='{ err: errNewPassword2, disabled: score < 2 }'
			@input='errNewPassword2 = false'
		/>
		<PasswordMatch :a='newPassword1' :b='newPassword2' />
	</div>
	<SaveButton class='saveButton' @click='save' :disabled='!oldPassword || score < 2 || newPassword1 !== newPassword2' />
	<transition name='fade'><Loading v-show='working' /></transition>
</div></div>
</template>

<script>
import { isEmpty } from 'lodash'
import { smallModalMixin } from '@/components/common/mixins/modal'
import AlertModal from '@/components/common/modals/Alert'
import PasswordInput from '@/components/common/PasswordInput'
import PasswordStrengthInput from '@/components/common/PasswordStrengthInput'
import PasswordMatch from '@/components/common/PasswordMatch'
import SaveButton from '@/components/common/buttons/Save'
import Loading from '@/components/common/Loading'

export default {
	name: 'SettingsPassword',
	mixins: [smallModalMixin],	
	components: { PasswordInput, PasswordStrengthInput, PasswordMatch, SaveButton, Loading },
	data() {
		return {
			oldPassword: '',
			newPassword1: '',
			newPassword2: '',
			errOldPassword: false,
			errNewPassword1: false,
			errNewPassword2: false,
			score: 0,
			renderKey: 0,
			working: false
		}
	},
	computed: {
	},
	methods: {
		async save() {
			if (this.validate()) {
				this.working = true
				const [err] = await this.$store.dispatch('profile/changePassword', {
					oldPassword: this.oldPassword,
					password: this.newPassword1
				})
				this.working = false
				this.reset()									
				if (err) {
					this.errOldPassword = true
					this.showSmallModal(AlertModal, {
						title: this.$t('views.wrongPassword.title'),
						message: this.$t('views.wrongPassword.p1')
					})					
				} else {
					this.$store.dispatch('flash/showAction', 'saved')
				}
			}
		},
		validate() {
			const err = {}
			if (!this.oldPassword.length) err.oldPassword = true
			if (this.score < 2) err.newPassword1 = true
			if (!err.newPassword1 && this.newPassword1 !== this.newPassword2) err.newPassword2 = true
			if (isEmpty(err)) {
				return true
			} else {
				this.showErrs(err)
				return false
			}
		},
		showErrs(err) {
			if ('oldPassword' in err) this.errOldPassword = true
			if ('newPassword1' in err) this.errNewPassword1 = true
			if ('newPassword2' in err) {
				this.newPassword2 = ''
				this.errNewPassword2 = true
			}
		},
		reset() {
			this.score = 0
			this.oldPassword = ''
			this.newPassword1 = ''
			this.newPassword2 = ''
			this.errOldPassword = false
			this.errNewPassword1 = false
			this.errNewPassword2 = false
			this.renderKey++
		}
	}
}
</script>

<style lang='scss'>
.settingsPassword {
	padding-left: $size-gutter * 4;
	background: $color-white;

	> div {
		position: relative;
		max-width: 500px;
	}

	.field .disabled { opacity: 0.5; }

	.newPassword2 { position: relative; }

	.saveButton {
		margin-top: $size-gutter * 5;
		width: 250px;
		border: 1px solid $color-neutral-shadow;
	}
}
</style>
