<template>
<div class='settingsPersonal formContent'><div>
	<header>{{$t('views.settings.personal.title')}}</header>
	<div v-if="$store.state.profile.user.emailErr" class="emailErr">Please review and update your email address.</div>
	<div class='field'>
		<div class='label'>{{$t('elements.labels.username')}}:</div>
		<TextInput 
			v-model='username' 
			maxlength='32' 
			:placeholder='$t("views.settings.personal.phUsername")' 
			:class='{ err: errUsername }'
			@input='errUsername = false'
		/>
	</div>
	<div class='field'>
		<div class='label'>{{$t('elements.labels.firstname')}}:</div>
		<TextInput 
			v-model='firstname' 
			maxlength='40' 
			:placeholder='$t("views.settings.personal.phFirstname")' 
			:class='{ err: errFirstname }'
			@input='errFirstname = false'
		/>
	</div>
	<div class='field'>
		<div class='label'>{{$t('elements.labels.lastname')}}:</div>
		<TextInput 
			v-model='lastname' 
			maxlength='40' 
			:placeholder='$t("views.settings.personal.phLastname")' 
			:class='{ err: errLastname }'
			@input='errLastname = false'
		/>
	</div>
	<div class='field'>
		<div class='label'>{{$t('elements.labels.email')}}:</div>
		<TextInput 
			v-model='email' 
			maxlength='100' 
			:placeholder='$t("views.settings.personal.phEmail")' 
			:class='{ err: errEmail }'
			@input='errEmail = false'
		/>
	</div>
	<SaveButton class='saveButton' @click='save' />
	<transition name='fade'><Loading v-show='working' /></transition>
</div></div>
</template>

<script>
import TextInput from '@/components/common/TextInput'
import SaveButton from '@/components/common/buttons/Save'
import Loading from '@/components/common/Loading'

export default {
	name: 'SettingsPersonal',
	components: { TextInput, SaveButton, Loading },
	data() {
		return {
			username: this.$store.state.profile.user.username,
			firstname: this.$store.state.profile.user.firstname,
			lastname: this.$store.state.profile.user.lastname,
			email: this.$store.state.profile.user.email,
			errUsername: false,
			errFirstname: false, 
			errLastname: false, 
			errEmail: false, 
			renderKey: 0,
			working: false
		}
	},
	computed: {
	},
	methods: {
		async save() {
			this.working = true
			const [err] = await this.$store.dispatch('profile/save', {
				username: this.username,
				firstname: this.firstname,
				lastname: this.lastname,
				email: this.email
			})
			this.working = false
			if (err) {
				this.username = this.$store.state.profile.user.username
				this.firstname =  this.$store.state.profile.user.firstname
				this.lastname=  this.$store.state.profile.user.lastname
				this.email=  this.$store.state.profile.user.email				
			//if (err && err.code === 'ERRINPUT' && err.details) {
			//	this.showErrs(err.details)
			} else if (!err) {
				this.$store.dispatch('flash/showAction', 'saved')
			}
		},
		showErrs(err) {
			if ('username' in err) this.errUsername = true
			if ('firstname' in err) this.errFirstname = true
			if ('lastname' in err) this.errLastname = true
			if ('email' in err) this.errEmail = true
		}
	}
}
</script>

<style lang='scss'>
.settingsPersonal {
	padding-left: $size-gutter * 4;
	background: $color-white;

	> div {
		position: relative;
		max-width: 500px;
	}

	.emailErr {
		font-size: $size-font-standard;
		color: $color-alert;
		margin-bottom: $size-gutter * 3;
	}

	.saveButton {
		margin-left: 100px;
		margin-top: $size-gutter * 5;
		width: 250px;
		border: 1px solid $color-neutral-shadow;
	}
}
</style>
